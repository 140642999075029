// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client: "luckkywin",
  luckkywin: {
    gamesEnable: {
      bingo: true,
      default: "bingo",
    },
    theme: ["bingo"],
    // domain: 'https://game.luckkywin.net/api',
    // SOCKETIO_ENDPOINT: 'https://game.luckkywin.net'
    domain:"/api",
    SOCKETIO_ENDPOINT:"https://beta-api.lukkywin.com",
    // domain: "http://192.168.29.233/api",
    // SOCKETIO_ENDPOINT: "http://192.168.29.233",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
