import { environment } from 'src/environments/environment';

export class ServerUrl {

    static readonly ADMIN_LOGIN         = `${environment[environment.client].domain}/admin/login`;
    static readonly ADMIN_LOGOUT        = `${environment[environment.client].domain}/admin/logout`;

    static readonly WEBSOCKET_DOMAIN    = `${environment[environment.client].webSocket_domain}`;

    static readonly SOCKETIO_ENDPOINT   = `${environment[environment.client].SOCKETIO_ENDPOINT}`
    static readonly ADMIN_ROOM_LIST     = `${environment[environment.client].domain}/admin/rooms`;
    static readonly ADMIN_USER_LIST     = `${environment[environment.client].domain}/admin/users`;
    static readonly RESET_USER_PASSWORD = `${environment[environment.client].domain}/admin/password-reset`;
    static readonly USER_ROOM_ASSIGN    = `${environment[environment.client].domain}/admin/room-assign`;
    static readonly GET_ROOM_DETAILS    = `${environment[environment.client].domain}/admin/room-details`;

    static readonly GET_BAD_WORDS_LIST      = `${environment[environment.client].domain}/admin/get-bad-words`;
    static readonly ADD_BAD_WORDS           = `${environment[environment.client].domain}/admin/add-bad-words`;
    static readonly EDIT_BAD_WORD_STATUS    = `${environment[environment.client].domain}/admin/update-bad-words`;
    static readonly UPDATE_USER_STATUS      = `${environment[environment.client].domain}/admin/update-user-status`;

    static readonly ADD_NEW_USER      = `${environment[environment.client].domain}/admin/create-new-user`;

    static readonly BINGO_GET_GAMEDATA  = `${environment[environment.client].domain}/bingo/fetchgamedata`;
}
