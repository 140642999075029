<div class="loaderOuterWrap" [class.hidden]="!show" >
  <div class="loaderbox" *ngIf="show">
    <div class="imgWrap"><img src="assets/images/logo-luckkywin.gif" alt=""></div>
    <div class="loaderBar">
      <div class="w3-border-orange">
        <div class="w3-grey" style="height:12px;width:1%"></div>
      </div>
    </div>
    <div class="loaderTxt">
      <span class="percent">1%</span> <span class="txt">  Loading ...</span>
    </div>
  </div>
</div>
