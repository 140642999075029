import { environment } from 'src/environments/environment';

export class Constants {

    static readonly TAB_WIDTH = 800;
    static readonly USER_TTL  = 1800000;
    static CLIENT_CODE = `${environment.client}`;

    static CLIENT_THEME = environment[environment.client].theme;

    static CLIENT_GAMES = environment[environment.client].gamesEnable;

    static readonly WEBSOCKET_USER = `${environment[environment.client].webSocket_user}`;
    static readonly WEBSOCKET_PSWD = `${environment[environment.client].webSocket_pswd}`;

}
