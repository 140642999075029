import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, first} from 'rxjs/operators';
import { AuthenticationService } from './../_services/index';

@Component({
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  routeSubscription: any;
  loading : boolean = false;
  loginFormErrorMessage = ""
  model: any = {};
    constructor(
      private router: Router,
      private authService : AuthenticationService
    ) {
      this.routeSubscription = router.events.pipe(
        filter(e => e instanceof ActivationEnd),
        first()
      ).subscribe(val => {});
    }


    loginSubmit(){
      this.loginFormErrorMessage = "";
      let username = this.model.username;
      let password = this.model.password;
      this.loading = true;
      this.authService.login(username,password).subscribe(loginResponse => {
        this.loading = false;
        if(loginResponse.success == true){
          this.router.navigate(['/']);
        }else{
          this.loginFormErrorMessage = loginResponse.responseMessage;
        }
      });
    }

    ngOnInit() {}

}
