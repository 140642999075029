import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs'
import * as io from 'socket.io-client';
import { ServerUrl } from '../_helpers';

@Injectable()
export class SocketService {

	private socket: any;
	private connected: boolean = false;
	CurrentTime: string;

	private resultPushObject = new Subject<any>();

	private dataStream : any = {};

	constructor() { }

	connect(roomId) {
		let _this = this;
		_this.disconnect();
		_this.socket = io(ServerUrl.SOCKETIO_ENDPOINT);
		_this.socket.on('connect',function(){
			_this.socket.emit('join',roomId);
			_this.subscribeEvents();
		})
	}

	subscribeEvents(){
		this.socket.on('resultBall', (data: any) => {
			this.resultPushObject.next(data);
		});
	}

	disconnect(){
		if(this.socket){
			this.socket.disconnect();
		}
	}

	subscribeDrawBallDisplay(): Observable<any> {
		return this.resultPushObject.asObservable();
	}

	getDataStream(){
		return this.dataStream;
	}

}
