import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ServerUrl } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class DrawService implements OnDestroy {
  private gameDataObserver = new Subject<any>();
  isMobile = false;
  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  orderingTicketList = {
    gameCode: '',
    orderBy: '',
    currentTab: 1
  };
  fromMyTickets = false;

  constructor(
    private dataservice: DataService,
    private alertService: AlertService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService
  ) {
    let isDesktopDevice = this.deviceService.isDesktop();
    this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? true : false;
  }

  ngOnDestroy(): void {
    clearInterval(DrawService.gameTimeInterval);
  }

  subscribeGameData(): Observable<any> {
    return this.gameDataObserver.asObservable();
  }

  getGameData = (roomId, playerDetails) => {
    this.returnFetchDataResponse(roomId, playerDetails).subscribe(response => {
      this.gameDataObserver.next(response);
    });
  }


  returnFetchDataResponse(roomId, playerDetails) {
    return this.dataservice.postData(ServerUrl.BINGO_GET_GAMEDATA, { roomId: roomId, playerInfo: playerDetails }, { withCredentials: true })
      .pipe(map((response: any) => {
        if (typeof response.data != 'undefined' && response.data.code == 200 && response.data.data.scheduledGamesData != 'undefined') {
          response.roomId = roomId;
          return response;
        } else {
          this.alertService.error(this.translate.instant(`error.dge.${response.responseCode}`));
        }
      }));
  }

  changeDateFormat(date, joinParam = '/', reverse = false) {
    /* "dd-mm-YYYY HH:ii:ss" to "YYYY-mm-dd HH:ii:ss" */
    if (reverse) {
      let tempArray = date.replace(/-/gi, "/").split('.')[0].split(" ");
      tempArray[0] = tempArray[0].split('/').reverse().join('/');
      return tempArray.join(" ");
    } else {
      // return date.replace(/-/gi, "/").split('.')[0];
      date = date.replace('T', " ").split('.')[0];
      let tempArray = date.replace(/-/gi, "/").split(" ");
      tempArray[0] = tempArray[0].split('/').reverse().join('/');
      return tempArray.join(" ");
    }
  }
}
