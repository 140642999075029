import { DataService } from '../_helpers/data-service-handle';
import { AppErrorHandler } from './../_helpers/app-error-handle';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServerUrl } from '../_helpers';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LobbyService {
    private roomListObserver = new Subject<any>();

    constructor(private dataservice: DataService) { }
    getRoomList() {
        this.dataservice.getData(ServerUrl.ADMIN_ROOM_LIST, { }, { withCredentials: true }).subscribe(response => {
            this.roomListObserver.next(response);
        })
    }

    getUserList() {
        return this.dataservice.getData(ServerUrl.ADMIN_USER_LIST, { }, { withCredentials: true })
        .pipe(map((response: any) => {
            return response;
        }))
    }

    resetPassword(userId) {
        return this.dataservice.postData(ServerUrl.RESET_USER_PASSWORD, { userId }, { withCredentials: true })
        .pipe(map((response: any) => {
            return response;
        }))
    }

    assignRoomToUser(userId,chatRoomArray) {
        return this.dataservice.postData(ServerUrl.USER_ROOM_ASSIGN, { userId, chatRoomId:chatRoomArray }, { withCredentials: true })
        .pipe(map((response: any) => {
            return response;
        }))
    }

    addNewUser(userName,startDate,endDate) {
        return this.dataservice.postData(ServerUrl.ADD_NEW_USER, { userName,startDate,endDate }, { withCredentials: true })
            .pipe(map((response: any) => {
                return response;
            }))
    }

    getRoomDetails(){
        return this.dataservice.getData(ServerUrl.GET_ROOM_DETAILS, { }, { withCredentials: true })
        .pipe(map((response: any) => {
            return response;
        }))
    }

    updateUserStatus(userId,Status){
        return this.dataservice.postData(ServerUrl.UPDATE_USER_STATUS, {userId,Status}, { withCredentials: true })
        .pipe(map((response: any) => {
            return response;
        }))
    }

    getBadWordsList(){
        return this.dataservice.getData(ServerUrl.GET_BAD_WORDS_LIST, {  }, { withCredentials: true })
            .pipe(map((response: any) => {
                return response;
            }))
    }

    addBadWords(badWords){
        return this.dataservice.postData(ServerUrl.ADD_BAD_WORDS, { badWords }, { withCredentials: true })
            .pipe(map((response: any) => {
                return response;
            }))
    }

    updateBadWord(id,status){
        return this.dataservice.postData(ServerUrl.EDIT_BAD_WORD_STATUS, { id,status  }, { withCredentials: true })
            .pipe(map((response: any) => {
                return response;
            }))
    }

    subscribeRoomList(): Observable<any> {
        return this.roomListObserver.asObservable();
    }

}
