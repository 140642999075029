import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../_helpers';
@Injectable()
export class AuthGuardEnable implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let engine = route.data["engine"] as Array<string>;
        let enableGames = Constants.CLIENT_GAMES;
        if (enableGames[engine[0]]) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'+enableGames.default]);
        return false;
    }

}
