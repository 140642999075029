import { DataService } from '../_helpers/data-service-handle';
import { Injectable ,OnDestroy } from '@angular/core';
import { ServerUrl } from '../_helpers';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ChatSocketService implements OnDestroy {

    public chatUserData     : any = {
    //    "roomId":{
    //        "chatroomId":[
    //            { "message" : "message","type":"message","data":"" }
    //        ],
    //        userStats:{},
    //        userList : []
    //    }
    }; 

    private chatConfig      : any       = {}
    private socket          : any;
    private chatEvents      : any       = new Subject<any>();
    private selfEvents      : any       = { 
        "chatNewMessage"        : true, 
        "chatNewUserJoined"     : true,
        "chatConnected"         : true, 
        "chatJoined"            : true ,
        "chatUpdateUserList"    : true,
        "chatSystemMessage"     : true,
        "chatBlockedUsersList"  : true
    };
    private activeSocket    : any       = {};
    constructor(
        private authService : AuthenticationService
    ) {
        try{
            let userData                = localStorage.getItem('userData');
            let parsedUserData          = JSON.parse(userData);
            if(!parsedUserData.chatData){
                parsedUserData.chatData = {};
            }
            this.chatConfig = parsedUserData.chatData;            
            this.connect();
        }catch(error){
            console.error(error);
        }
    }
 
    connect(){
        // This is actual Function which will connect to socket connection
        if(!this.socket || !this.socket.connected){
            this.socket = io(ServerUrl.SOCKETIO_ENDPOINT);
            this.socket.on('connect', () => {
                // This will reconnect to all the room user was joined
                console.log('SOCKET CONNNECTED');
                this.subscribeEvents();
                this.connectLocalStorageUserToServer();
            });
        }
    }


    chatLeave(roomId, chatId){
        this.sendData('chatLeave', {}, roomId, chatId);
        delete this.activeSocket[chatId];
    }



    resetSocketObject(){
        this.activeSocket = {};
        this.chatUserData = {};
    }


    getActiveSocketData(){
        return this.activeSocket;
    }

    sendData(eventName,data,roomId,chatId){
        // This is single event that will be sent to server
        let userData = this.authService.getUserData();
        let SocketUserData = { 
            token:userData.userInfo.token,
            userId:userData.userInfo.userInfo.userId,
            userName:userData.userInfo.userInfo.userName 
        };
        this.socket.emit('chatEventToServer',{ 
            eventName,
            evenId : uuidv4(),
            roomId,
            chatId,
            data,
            userData:SocketUserData 
        });
    }

    subscribeEvents(){
        debugger;
        // this is the only event recieved by client
        this.socket.on('chatEventToClient',(responseData : any) => {
            console.log('chatEventToClient');
            let {eventName,data,roomId,chatId,evenId } = responseData;
            debugger;
            if(this.selfEvents[eventName]){
                this.executeSelfEvent(eventName,data,roomId,chatId,evenId);
            }else{
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
            }
        });
    }

    recieveEvent(eventName,data,roomId,chatId,evenId){
        this.chatEvents.next({
            eventName,
            data,
            roomId,
            chatId,
            evenId
        })
    }

    resultEvent(roomId,drawResult){
        for(let chatId in this.chatUserData[roomId]){
            if(chatId == "blockedUserList") continue;
            this.setStreamObjectIfNotExists(roomId,chatId);
            this.chatUserData[roomId][chatId]['messageQueue'].push({
                data     : drawResult ,
                type     : "drawResult"
            })
        }
    }




    disconnect(){
        // To Disconnect the socket
		if(this.socket){
            this.socket.disconnect();
            this.socket.connected = false;
        }
        this.activeSocket = {};
    }

    getChatEvents(){
        //this will be subscribed to all the components
        return this.chatEvents.asObservable();
    }


    broadcaseToAll(message){
        this.sendData('broadcastToAll', {message}, 0, 0);
    }

    executeSelfEvent(eventName,data,roomId,chatId,evenId){
        debugger;
        // Event where preprocessing is needed by service
        switch(eventName){
            case 'chatJoined' : {
                // Trigger by server when user is joined to a chatRoom
                this.updateLocalStorageObject(eventName,data,roomId,chatId,evenId);
                break;
            }
            case 'chatUpdateUserList' : {
                this.setStreamObjectIfNotExists(roomId,chatId);
                this.chatUserData[roomId][chatId]['userList'] = data;
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
                break;   
            }
            case 'chatNewUserJoined' : {
                this.setStreamObjectIfNotExists(roomId,chatId);
                let { userId , userName, timestamp,message } = data;
                this.chatUserData[roomId][chatId]['messageQueue'].push({message ,type:"newUser",timestamp}) //= data;
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
                break;   
            }
            case 'chatNewMessage' : {
                this.setStreamObjectIfNotExists(roomId,chatId);
                let { message , userName, timestamp } = data;
                this.chatUserData[roomId][chatId]['messageQueue'].push({ message , userName ,type : "message",timestamp}) //= data;
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
                break;   
            }

            case 'chatBlockedUsersList' : {
                this.setStreamObjectIfNotExists(roomId,chatId);
                let { blockedUserList } = data;
                this.chatUserData[roomId]['blockedUserList'] = blockedUserList;
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
                break;   
            }

            case 'chatSystemMessage' : {
                if(roomId == 0 && chatId == 0){
                    for(let rid in this.chatUserData){
                        let roomObject = this.chatUserData[rid];
                        for(let cid in roomObject){
                            if(cid == "blockedUserList") continue;
                            this.setStreamObjectIfNotExists(roomId,chatId);
                            let { message , userName, timestamp } = data;
                            this.chatUserData[rid][cid]['messageQueue'].push({ message , userName ,type : "systemMessage",timestamp}) //= data; 
                        }
                    }
                }else{
                    this.setStreamObjectIfNotExists(roomId,chatId);
                    let { message , userName, timestamp } = data;
                    this.chatUserData[roomId][chatId]['messageQueue'].push({ message , userName ,type : "systemMessage",timestamp}) //= data;    
                }
                this.recieveEvent(eventName,data,roomId,chatId,evenId);
                break;   
            }
        }
    }

    connectLocalStorageUserToServer(){
        // this will init to join a chatRoom
        for(let ind in this.chatConfig){
            let config = this.chatConfig[ind];
            this.sendData('chatJoin', {}, config.roomId, config.chatId);
        }
    }

    updateLocalStorageObject(eventName,data,roomId,chatId,evenId){
        // This will add the data to localStorage
        debugger;
        try{
            let userData                    = localStorage.getItem('userData');
            let parsedUserData              = JSON.parse(userData);
            if(!parsedUserData.chatData){
                parsedUserData.chatData         = {};
            }
            
            parsedUserData.chatData[chatId] = { roomId,chatId };
            if(!this.activeSocket){ this.activeSocket = {}; }
            this.activeSocket[chatId] = { roomId,chatId }
            localStorage.setItem('userData',JSON.stringify(parsedUserData));
            this.recieveEvent(eventName,data,roomId,chatId,evenId);
        }catch(error){
            console.log(error);
        }
    }

    //PUBLIC FUNCTION FOR SERVICE 
    joinRoom(chatId,roomId){
        console.log(chatId,roomId);
        this.sendData('chatJoin', {}, roomId, chatId);
    }

    returnObject(roomId,chatId){
        this.setStreamObjectIfNotExists(roomId,chatId);
        debugger;
        return {
            dataStream          : this.chatUserData[roomId][chatId],
            blockedUserList     : this.chatUserData[roomId]['blockedUserList'],
            sendMessage         : ( message ) => { this.sendMessage(roomId,chatId,message); },
            updateUserStats     : ( ) => { this.updateUserStats(roomId,chatId); },
            muteUser            : (userName) => {  this.sendData("chatAdminMute",{userName},roomId,chatId);},
            ejectUser           : (userName) => {  this.sendData("chatAdminEject",{userName},roomId,chatId);},
            banUser             : (userName) => {  this.sendData("chatAdminBan",{userName},roomId,chatId);},
            unblockUser         : (userName) => {  this.sendData("chatUnblockUser",{userName},roomId,chatId);},
        }
    }

    setStreamObjectIfNotExists(roomId,chatId){
        debugger
        if(!this.chatUserData){
            this.chatUserData = {};
        }

        if(!this.chatUserData[roomId]){
            this.chatUserData[roomId] = {};
        }

        if(!this.chatUserData[roomId][chatId]){
            this.chatUserData[roomId][chatId] = { messageQueue : [] , userList : {},userStats : null};
        }
    }

    sendMessage(roomId,chatId,message){
        this.sendData("chatMessage",{message},roomId,chatId);
    }

    updateUserStats(roomId,chatId){
        this.sendData("getuserStats",{},roomId,chatId);
    }

    ngOnDestroy() {
        this.disconnect();
    }

}
