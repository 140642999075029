import { Component, HostListener, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './_helpers';
import { Router, ActivationEnd } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { LoaderService } from './_services';

@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.sizeChecker();
  }

  // isIframe = false;
  isTab = false;
  routeSubscription: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    private loaderService: LoaderService,
  ) {
    this.loaderService.show();
    // if (AppConfig.CLIENT_CONFIG[Constants.CLIENT_CODE].iframe) {
    //   this.isIframe = true;
    // }
    for (let i = 0; i < Constants.CLIENT_THEME.length; i++) {
      this.renderer.addClass(document.body, Constants.CLIENT_THEME[i]);
    }
    this.routeSubscription = router.events.pipe(
      filter(e => e instanceof ActivationEnd),
      first()
    ).subscribe(val => {
      let lang = '';
      let tempVar = <any>val;
      let params = tempVar.snapshot.params;
      if (typeof params.lang != 'undefined' && params.lang != '-') {
        lang = params.lang;
        translate.setDefaultLang(lang);
        localStorage.setItem('lang', lang);
      } else {
        lang = localStorage.getItem('lang');
        if (lang == null || lang == '-') {
          lang = 'en';
          translate.setDefaultLang(lang);
          localStorage.setItem('lang', lang);
        } else {
          translate.setDefaultLang(lang);
        }
      }
      if (lang == 'fr') {
        this.renderer.addClass(document.body, 'ln-fr');
      }
    });
  }

  ngOnInit() {
    this.sizeChecker();
  }

  sizeChecker() {
    if (window.innerWidth >= Constants.TAB_WIDTH) {
      this.isTab = true;
    } else {
      this.isTab = false;
    }
  }

  ngOnDestroy() {
    if (typeof this.routeSubscription != 'undefined') {
      this.routeSubscription.unsubscribe();
    }
  }

}
