import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  
  constructor(private http: HttpClient) { }

  getAuthHeaders(headers){
    const token = localStorage.getItem('jwt');
    let httpHeaders = new HttpHeaders(headers);
    if (token) {
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }
    // return {...headers,"Authorization":"Bearer "+localStorage.getItem('jwt')};
    return httpHeaders;
  }

  getData(url, resource, headers){
    // const headersWithAuth = this.getAuthHeaders(headers);
    // const response = this.http.get<any>(url, headersWithAuth);
    // debugger;
    const headersWithAuth = this.getAuthHeaders(headers);
    const options = { headers: headersWithAuth };
    return this.http.get<any>(url, options);
  }

  postData(url, resources, headers){   
    const headersWithAuth = this.getAuthHeaders(headers);
    const options = { headers: headersWithAuth };
    return this.http.post<any>(url, resources, options );
  }

  updateData(url, resources, headers){    
    const headersWithAuth = this.getAuthHeaders(headers);
    const options = { headers: headersWithAuth };
    return this.http.patch<any>(url, resources, options);
  }

  removeData(url, resources, headers){    
    const headersWithAuth = this.getAuthHeaders(headers);
    const options = { headers: headersWithAuth };
    return this.http.delete<any>(url, options);
  }
  
}