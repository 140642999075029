import { DataService } from '../_helpers/data-service-handle';
import { AppErrorHandler } from './../_helpers/app-error-handle';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServerUrl } from '../_helpers';
import { v4 as uuidv4 } from 'uuid';
import MD5 from 'md5';
import { Constants } from '../_helpers';
@Injectable()
export class AuthenticationService {

    constructor(private dataservice: DataService) { }
    login(username: string, password: string) {
        let loginToken  = uuidv4();
        password        = MD5(MD5(password) + loginToken);
        return this.dataservice.postData(ServerUrl.ADMIN_LOGIN, { username: username, password: password,loginToken : loginToken }, { withCredentials: true })
            .pipe(map((response: any) => {
                if (response.success && response.data) {
                    localStorage.setItem('jwt',response.data.jwt_token);
                    localStorage.setItem('userData', JSON.stringify({userInfo : response.data,timeStamp:new Date().getTime()}));
                }
                return response;
            }));
    }

    getUserData() {
        let userData = localStorage.getItem('userData');
        let parsedUserData = null;
        if(userData && userData.length > 0){
            try{
                let parsedData = JSON.parse(userData);
                if(parsedData && parsedData['timeStamp']){
                    let lastUpdateTime      = parsedData['timeStamp'];
                    let currentTimeStamp    = new Date().getTime();
                    let timeDifferenct = currentTimeStamp - lastUpdateTime;
                    
                    if(timeDifferenct > 0 && timeDifferenct < Constants.USER_TTL){
                        parsedData['timeStamp'] = new Date().getTime();
                        localStorage.setItem('userData',JSON.stringify(parsedData));
                        parsedUserData = parsedData;
                    }else{
                        localStorage.setItem('userData',null);
                    }
                }
            }catch(error){}
        }
        return parsedUserData;
    }

    logout() {
        localStorage.setItem('userData',null);
        this.dataservice
            .getData(ServerUrl.ADMIN_LOGOUT, {}, { withCredentials: true })
            .subscribe(function(response){
                
            });
    }

}
