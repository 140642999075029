import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertService} from '../_services';
import { AlertComponent } from './alert';
import { IntegeronlyPipe, ArrayjoinPipe, OrdinalPipe, CurrencySymbolPipe, VarDirective ,ListFilterPipe } from '../_directives';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [
    AlertComponent,
    IntegeronlyPipe,
    ArrayjoinPipe,
    ListFilterPipe,
    OrdinalPipe,
    CurrencySymbolPipe,
    VarDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    PickerModule,
  ],
  exports: [
    AlertComponent,
    IntegeronlyPipe,
    ArrayjoinPipe,
    ListFilterPipe,
    OrdinalPipe,
    CurrencySymbolPipe,
    VarDirective
  ],
  providers: [AlertService]
})

export class SimilarModule { }