import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeMy from '@angular/common/locales/my';
import localeTh from '@angular/common/locales/th';
// import localeEn from '@angular/common/locales/en';

import { Constants } from '../_helpers';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class SessionService {

    defaultLang = '';

    constructor(
        private activatedRoute: ActivatedRoute,
    ) {
        let langArray = ['en', 'fr', 'es', 'th', 'mm'];
        this.defaultLang = langArray[0];
        let routeArray = this.activatedRoute['_routerState'].snapshot.url.split('/');
        if (langArray.indexOf(routeArray[6]) > -1) {
            this.defaultLang = routeArray[6];
        } else if (langArray.indexOf(routeArray[7]) > -1) {
            this.defaultLang = routeArray[7];
        } else if (typeof routeArray[6] == 'undefined') {
            this.defaultLang = localStorage.getItem('lang');
        }
    }

    private _locale: string;

    set locale(value: string) {
        this._locale = value;
    }

    get locale(): string {
        let culture = 'en-US';
        if (this.defaultLang == 'fr') {
            culture = 'fr-CM';
            this.registerCulture(localeFr, culture);
        } else if (this.defaultLang == 'es') {
            culture = 'es-ES';
            this.registerCulture(localeEs, culture);
        } else if (this.defaultLang == 'th') {
            culture = 'th-TH';
            this.registerCulture(localeTh, culture);
        } else if (this.defaultLang == 'mm') {
            culture = 'my-MM';
            this.registerCulture(localeMy, culture);
        }
        return this._locale || culture;
    }

    registerCulture(locale, culture: string) {
        this.locale = culture;
        // Register locale data since only the en-US locale data comes with Angular
        registerLocaleData(locale, culture);
    }

}