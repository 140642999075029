import { Routes, RouterModule } from '@angular/router';
// import { ModuleWithProviders } from '@angular/core';
import { AuthGuard, AuthGuardLogin, AuthGuardEnable } from './_guards';
import { LoginComponent } from "./login";

const appRoutes: Routes = [
    { path: 'lobby', loadChildren: () => import('./lobby/lobby.module').then(m => m.LobbyModule), canActivate: [AuthGuard], data: { } },
    { path: 'login', component: LoginComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: 'lobby' }
];

export const routing = RouterModule.forRoot(appRoutes);
// export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: false, onSameUrlNavigation: 'reload' });
