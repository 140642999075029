 <div id="loginForm">
        <div class="container">
            <div class="login-form">
                <div class="main-div">
                    <div class="panel">
                        <img src="assets/images/logo_chatter.png" alt="Chatter Box">
                        <p>Please enter your username and password</p>
                    </div>
                    <form id="Login" (ngSubmit)="loginForm.form.valid && loginSubmit()" #loginForm="ngForm" novalidate>
                        <div class="form-group">
                            <input type="text" name="username" [(ngModel)]="model.username" #username="ngModel" required class="form-control username" placeholder="User Name">
                            <div *ngIf="loginForm.submitted && !username.valid" class="help-block p-Abs">Username is required</div>
                        </div>
                        <div class="form-group">
                            <input type="password" name="password" [(ngModel)]="model.password" #password="ngModel"  class="form-cont rol password" placeholder="Password" required>
                            <div *ngIf="loginForm.submitted && !password.valid" class="help-block p-Abs">Password is required</div>
                        </div>


                        <div class="form-group">
                            <div *ngIf="loginForm.submitted && loginFormErrorMessage" class="help-block p-Abs">{{loginFormErrorMessage}}</div>
                        </div>
                        <!-- <div class="forgot">
                            <a href="#">Forgot password?</a>
                        </div> --> 
                        <button 
                            type="submit"
                            class=" btn btn-primary" 
                            [disabled]="loading">
                            <span *ngIf="!loading" >Login</span>
                            <span *ngIf="loading" >Please Wait...</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>